import { useLoginMutation, useRegisterMutation } from 'apis/auth.api';
import { useDispatch } from 'react-redux';
import { setUserToken } from 'slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { mlmApi } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
export const useAuthService = () => {
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();

  const dispatch = useDispatch();

  const logout = async () => {
    try {
      dispatch(mlmApi.util.resetApiState())
      dispatch(stripeApi.util.resetApiState())

      dispatch(setUserToken(null));
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials) => {
    try {
      const result = await loginMutation(credentials).unwrap();
      dispatch(setUserToken(result.token));
      navigate('/');
      
      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const result = await registerMutation(userData).unwrap();
      dispatch(setUserToken(result.token));
      navigate('/');
      return result;
    } catch (error) {
      throw error;
    }
  };

  return { login, register, logout};
};