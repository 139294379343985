import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from 'slices/auth.slice';
import { authApi } from 'apis/auth.api'; 
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { mlmApi } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
// ==============================|| COMBINE REDUCER ||============================== //

const persistConfig = {
  key: 'root',
  storage:sessionStorage,
}
const persistedReducer = persistReducer(persistConfig, authReducer)

const reducer = combineReducers({
  customization: customizationReducer,
  auth : persistedReducer,
  [authApi.reducerPath]: authApi.reducer,
  [mlmApi.reducerPath]: mlmApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,


});

export default reducer;
