import { createSlice } from '@reduxjs/toolkit'

const initialState = { token: null } 

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken(state,  action) {
      state.token = action.payload;
      console.log("New token", state.token)
    },
    unsetUserToken(state) {
      state.token = null;
    },
  },
})

export const { setUserToken, unsetUserToken } = authSlice.actions
export default authSlice.reducer