import workspace from './workspace';
import me from './me';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [workspace, me]
};

export default menuItems;
