import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';

// routes
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import { useSelector } from 'react-redux';
import { useGetAgentInfosQuery } from 'apis/mlm.api';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const PackagesPage = Loadable(lazy(() => import('views/packages-page')));
const EWalletPage = Loadable(lazy(() => import('views/e-wallet-page')));
const OperationsPage = Loadable(lazy(() => import('views/operations-page')));
const OperationDetailsPage = Loadable(lazy(() => import('views/operations-page/OperationDetailsPage')));

const InvoicesPage = Loadable(lazy(() => import('views/invoices-page')));
const InvoiceDetailsPage = Loadable(lazy(() => import('views/invoices-page/InvoiceDetailsPage')));
const AccountsPage = Loadable(lazy(() => import('views/accounts-page')));
const AccountDetailsPage = Loadable(lazy(() => import('views/accounts-page/AccountDetailsPage')));
const AgentRegistration = Loadable(lazy(() => import('views/agent/AgentRegistration')));
const NetworkPage = Loadable(lazy(() => import('views/network-page')));
const OnlineShop = Loadable(lazy(() => import('views/online-shop')));
const NetworkRegistration = Loadable(lazy(() => import('views/network-page/NetworkRegistration')));
const ChangePassword = Loadable(lazy(() => import('views/settings/ChangePassword')));




const AuthGuard = Loadable(lazy(() => import('routes/AuthGuard')));

// =================
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const token = useSelector((state) => state.auth.token);
  const { data: agentInfos, isLoading } = useGetAgentInfosQuery(null, {skip:token==null});
  return (
    <Routes>
      <Route path="/auth" element={<MinimalLayout />}>
        <Route path="login" element={<AuthLogin3 />} />
        <Route path="register" element={<AuthRegister3 />} />
        <Route path='' element={<Navigate to='/auth/login' />} />
      </Route>
      
      <Route element={<AuthGuard token={token} agentInfos={agentInfos} isLoading={isLoading}/>}>
        <Route path="/" element={<MainLayout />}>
          <Route path="workspace">
            <Route path="dashboard" element={<DashboardDefault />} />
            <Route path="packages" element={<PackagesPage />} />
            <Route path="network" element={<NetworkPage />} />
            <Route path="network/registration" element={<NetworkRegistration />} />
            <Route path="settings/change-password" element={<ChangePassword />} />

            <Route path="shop" element={<OnlineShop />} />

            <Route path='' element={<Navigate to='/workspace/dashboard' />} />
          </Route>
          <Route path="me">
            <Route path="operations" element={<OperationsPage />} />
            <Route path="invoices" element={<InvoicesPage />} />
            <Route path="invoices/:invoiceId" element={<InvoiceDetailsPage />} />
            <Route path="accounts" element={<AccountsPage />} />
            <Route path="accounts/:accountId" element={<AccountDetailsPage />} />
            <Route path="agent-registration" element={<AgentRegistration />} />
           
            <Route path='' element={<Navigate to='/me/accounts' />} />
          </Route>
          <Route path='' element={<Navigate to='/workspace' />} />
        </Route>
      </Route>
    </Routes>
  )
}
