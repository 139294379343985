// assets
import { IconDashboard, IconBasket, IconNetwork , IconWorld} from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconBasket , IconNetwork, IconWorld};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const workspace = {
  id: 'workspace',
  title: 'Mon espace de travail',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Tableau de bord',
      type: 'item',
      url: '/workspace/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'packages',
      title: 'Packs',
      type: 'item',
      url: '/workspace/packages',
      icon: icons.IconBasket,
      breadcrumbs: false
    },
    {
      id: 'network',
      title: 'Mon réseau',
      type: 'item',
      url: '/workspace/network',
      icon: icons.IconNetwork,
      breadcrumbs: false
    },
    {
      id: 'shop',
      title: 'Ma boutique en ligne',
      type: 'item',
      url: '/workspace/shop',
      icon: icons.IconWorld,
      breadcrumbs: false
    },
    // Should add like : Operations, Soldes, etc
  ]
};

export default workspace;
